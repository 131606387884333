import React, {FC} from 'react';
import {ButtonProps} from './button.types';
import './_button.scss';

export const ButtonComponent: FC<ButtonProps> = ({
  mode = 'green',
  text,
  sizeModifier,
  disabled,
  onClick,
  type,
  fullWidth,
}) => {
  const classesArray = ['button'];

  if (mode === 'green') {
    classesArray.push('button--green');
    classesArray.push('button--h60');
  }
  if (mode === 'light-green') {
    classesArray.push('button--light-green');
    classesArray.push('button--h40');
  }
  if (mode === 'form-button') {
    classesArray.push('button--form-button');
  }
  if (mode === 'dark-text') {
    classesArray.push('button--light-green');
    classesArray.push('button--dark-text');
    classesArray.push('button--h60');
  }
  if (fullWidth) {
    classesArray.push('button--full-width');
  }
  if (sizeModifier) {
    classesArray.push(`button--${sizeModifier}`);
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type || 'button'} onClick={onClick} disabled={disabled} className={classesArray.join(' ')}>
      <span className="button__text">{text}</span>
    </button>
  );
};
