import React, {useEffect, useState} from 'react';
import {Props} from './person-images.types';
import './_person-images.scss';
import defaultImage from './images/default2x.png';

const ImageComponent = ({src, onError}: {src: string; onError: (src: string) => void}) => (
  <img src={src} onError={() => onError(src)} className="person-images__current-image" alt="profile picture" />
);

export const PersonImagesComponent: React.FC<Props> = ({images}) => {
  const [currentImage, setCurrentImage] = useState<string>();
  const [validImages, setValidImages] = useState<Array<string>>(images.map(item => item.value));

  useEffect(() => setValidImages(images.map(item => item.value)), [images]);

  useEffect(() => {
    if (!currentImage || validImages.indexOf(currentImage) < 0) {
      validImages.length ? setCurrentImage(validImages[0]) : setCurrentImage(undefined);
    }
  }, [validImages, currentImage]);

  return (
    <div className={`person-images${!images.length ? ' person-images-empty' : ''}`}>
      <div className="person-images__current-image-wrapper">
        <ImageComponent
          src={currentImage || defaultImage}
          onError={src => setValidImages(validImages.filter(item => item !== src))}
        />
      </div>
      <div className="person-images__images-bar">
        {validImages.map((image, index) => (
          <div
            key={index}
            className={`person-images__images-bar-image-wrapper${
              image === currentImage ? ' person-images__images-bar-image-wrapper--active' : ''
            }`}
            onClick={() => setCurrentImage(image)}
          >
            <ImageComponent src={image} onError={src => setValidImages(validImages.filter(item => item !== src))} />
          </div>
        ))}
      </div>
    </div>
  );
};
