import {RiskTypes} from '@js/landing/redux/constants';
import React from 'react';
import {CategoryItemIconComponent} from '../../../pure-components/category-item-icon/category-item-icon.component';
import {RiskBadgeComponent} from '../../../pure-components/risk-badge/risk-badge.component';
import {DataItemContainer} from '../data-item/data-item.container';

import {Props} from './category-item-with-subitems.types';

export const CategoryItemWithSubitemsComponent: React.FC<Props> = props => {
  let isItemDataAvailable = false;
  const data = Object.entries(props.subItems).map(([key, subItem], index) => {
    const isSubitemDataAvailable = Boolean(subItem?.data.length);
    let text = subItem?.data.map(item => item.value).join(', ');
    const isHidden = subItem?.data[0] ? subItem?.data[0].hidden : false;
    if (isSubitemDataAvailable) {
      isItemDataAvailable = true;
    } else {
      if (props.isDataFetched) {
        text = 'Not found';
      } else {
        text = 'N/A';
      }
    }
    return (
      <DataItemContainer
        key={`${key}_${props.itemKey}_${index}`}
        disabled={!isSubitemDataAvailable}
        title={subItem?.name}
        text={text || ''}
        hidden={isHidden}
      />
    );
  });

  return (
    <div className="category-item">
      <div className="category-item__name">
        <CategoryItemIconComponent itemName={props.itemKey} />
        <h6 className="category-item__title">{props.itemName}</h6>
      </div>
      <div className="category-item__data">{data}</div>
      <div className="category-item__badge">
        {isItemDataAvailable ? <RiskBadgeComponent text={props.riskBadge} riskType={RiskTypes.high} /> : null}
      </div>
    </div>
  );
};
