import React, {FC} from 'react';
import IconPriceTagCurrentColor from '@common/images/icon-price-tag-current-color.svg';
import './_discount-badge.scss';

export const DiscountBadgeComponent: FC = props => {
  return (
    <div className="discount-badge">
      <IconPriceTagCurrentColor className="discount-badge__price-tag" />
      {props.children}
    </div>
  );
};
