import React, {FC} from 'react';
import {InputProps} from './transparent-input.types';
import './_transparent-input.scss';

export const TransparentInputComponent: FC<InputProps> = ({
  value,
  onChange,
  placeholder,
  isValid,
  errorMessage,
  type,
}) => {
  return (
    <div className="transparent-input__wrapper">
      <input
        type={type || 'text'}
        className={isValid ? 'transparent-input' : 'transparent-input transparent-input--invalid'}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
      {!isValid && errorMessage && <span className={'transparent-input__error-message'}>{errorMessage}</span>}
    </div>
  );
};
