import React, {FC} from 'react';
import {InputProps} from './white-input.types';
import './_white-input.scss';

export const WhiteInputComponent: FC<InputProps> = ({value, onChange, placeholder, isValid, errorMessage, type}) => {
  return (
    <div className="white-input__wrapper">
      <input
        type={type || 'text'}
        className={isValid ? 'white-input' : 'white-input white-input--invalid'}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
      {!isValid && errorMessage && <span className={'white-input__error-message'}>{errorMessage}</span>}
    </div>
  );
};
