import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {hot} from 'react-hot-loader';
import {store} from './redux/store';
import {LandingContainer} from './components/page/landing.container';

const App = () => (
  <Provider store={store}>
    <Router>
      <Route path="/" component={LandingContainer} />
    </Router>
  </Provider>
);

export default hot(module)(App);
