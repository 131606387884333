export const categoriesConfig = {
  identityTheft: {
    title: 'Identity Exposure',
    description: `Your identity consists of delicate pieces of information. When that information is exposed, 
    it can be used to access your bank accounts, open credit in your name, and create synthetic identities.`,
    descriptionKeep: undefined,
    buttonProtectText: 'Protect My Privacy',
    buttonKeepText: 'Keep My Privacy Protected',
  },
  accountHijacking: {
    title: FL_LANDING_PRODUCT === 'acer' ? 'Accounts Exposure' : 'Account Hijacking',
    description:
      FL_LANDING_PRODUCT === 'acer'
        ? `Hackers who obtain the login credentials for one of your digital accounts may be able to hijack other accounts, especially if you use the same passwords.`
        : `Hijacked account credentials are abused by hackers to see how many of your accounts they can gain access to. Because passwords are often re-used, this information is a serious threat to your digital security.`,
    descriptionKeep: `Fortunately, we did not find exposed accounts. With over a 1 billion newly exposed records coming in monthly your accounts can be exposed in the future with any upcoming data breach.`,
    buttonProtectText: 'Protect My Accounts',
    buttonKeepText: 'Keep My Accounts Protected',
  },
  homeTitleTheft: {
    title: FL_LANDING_PRODUCT === 'acer' ? 'Location Exposure' : 'Home Address Exposure',
    description:
      FL_LANDING_PRODUCT === 'acer'
        ? 'Every web site you visit knows where you live, and when combined with other info, can mine your personal details to build a rich profile. Your IP address is the cornerstone to targeting you, the ultimate breach of privacy.'
        : `When a criminal forges a fake deed, they can easily change your home ownership from you to them. They take out loans using your house as collateral, and can even try to sell it out from under you.`,
    descriptionKeep: `Information about your location has not been accessed but could still become more broadly available. When combined with other info, can mine your personal details to build a rich profile. Your IP address is the cornerstone to targeting you, the ultimate breach of privacy.`,
    buttonProtectText: FL_LANDING_PRODUCT === 'acer' ? 'Protect My Location' : 'Protect From Exposure',
    buttonKeepText: 'Keep My Location Protected',
  },
  creditTheft: {
    title: 'Credit Theft',
    description: `If your personal credit information is used to fraudulently open a credit card, apply for a loan, 
    or finance a vacation, it can destroy the reputation that you’ve built with lenders.`,
    descriptionKeep: undefined,
    buttonProtectText: 'Protect My Finances',
    buttonKeepText: 'Keep My Finances Protected',
  },
  spamRobocalls: {
    title: 'Spam & Robocalls',
    description: `Unwanted spam and robocalls may feel like just a nuisance, but publicly available contact 
    information can often be the symptom of a larger digital privacy issue.`,
    descriptionKeep: undefined,
    buttonProtectText: 'Protect from Spam',
    buttonKeepText: 'Keep My Privacy Protected',
  },
  dataTrading: {
    title: 'Data Trading',
    description: `Revealing personal information is collected when you make a purchase, use an app, or browse the web. 
    Then it’s given to data traders, who will sell your name, address, and more to anyone who’s interested.`,
    descriptionKeep: undefined,
    buttonProtectText: 'Protect My Privacy',
    buttonKeepText: 'Keep My Privacy Protected',
  },
  armaniDataTrading: {
    title: 'Data Trading',
    description: `Your private data is in the public domain. Criminals can take information about your browsing habits and combine it with what is known about you to be able to access your government issued details and financial information.`,
    descriptionKeep: `Fortunately, we did not find personally identifiable information (PII). Should your information become exposed, criminals can take information about your browsing habits and combine it with what is known about you to be able to access your government issued details and financial information.`,
    buttonProtectText: 'Protect My Privacy',
    buttonKeepText: 'Keep My Privacy Protected',
  },
} as const;
