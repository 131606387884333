import {connect} from 'react-redux';
import {getRiskCategories} from '@js/landing/redux/actions';
import {BreachIsThreatComponent} from './breach-is-threat.component';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  getRiskCategories,
};

export const BreachIsThreatContainer = connect(mapStateToProps, mapDispatchToProps)(BreachIsThreatComponent);
