function updateReferrer(referrer: string): void {
  const referrerFromStorage = localStorage.getItem('referrer');
  if (referrer === referrerFromStorage) {
    return;
  }

  localStorage.setItem('referrer', referrer);
}

export function getReferrer(payload: any): void {
  const {referrer} = document;
  const {host} = window.location;

  if (referrer && !referrer.includes(host)) {
    updateReferrer(referrer);
  }

  if (!referrer) {
    updateReferrer('undefined');
  }

  payload.referrer = localStorage.getItem('referrer') || 'undefined';
}
