import React, {FC} from 'react';
import {ButtonSpecialProps} from './button-special.types';
import './_button-special.scss';

export const ButtonSpecialComponent: FC<ButtonSpecialProps> = ({text, onClick}) => {
  return (
    <button type="button" onClick={onClick} className="button-special">
      <span className="button-special__text">{text}</span>
      <div className="button-special__badge">
        Special
        <br />
        Offer
      </div>
    </button>
  );
};
