import React, {FormEvent, memo, useState} from 'react';
import './_scan-email-again.scss';
import IconHero from '@common/images/icon-hero.svg';
import {ScanEmailAgainProps} from './scan-email-again.types';
import {WhiteInputComponent} from '@js/pure-components/white-input/white-input.component';
import {ButtonComponent} from '@js/pure-components/button/button.component';
import {emailRegex} from '@js/utils/helpers';

export const ScanEmailAgainComponent = memo<ScanEmailAgainProps>(({getRiskCategories}) => {
  const [isInputValueValid, setIsInputValueValid] = useState(true);
  const [inputValue, setInputValue] = useState<string>('');

  const handleSubmitEmail = (event: FormEvent) => {
    event.preventDefault();
    if (!emailRegex.test(inputValue)) {
      setIsInputValueValid(false);
    } else {
      getRiskCategories({email: inputValue, source: 'scanEmailAgain'});
      window.scrollTo({top: 0, behavior: 'smooth'});
      setInputValue('');
    }
  };

  const handleInputChange = (value: string) => {
    if (!isInputValueValid) {
      setIsInputValueValid(emailRegex.test(value));
    }
    setInputValue(value);
  };

  return (
    <section className="scan-email-again">
      <div className="scan-email-again__inner">
        <div className="scan-email-again__img" role="img">
          <IconHero />
        </div>
        <h2 className="scan-email-again__title">Be a privacy hero</h2>
        <p className="scan-email-again__text">Scan all your emails or family members you care about</p>
        <form
          className="scan-email-again__form"
          onSubmit={(event: FormEvent) => {
            handleSubmitEmail(event);
          }}
        >
          <div className="scan-email-again__form-field">
            <WhiteInputComponent
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Try another email..."
              isValid={isInputValueValid}
              errorMessage="Please, enter a valid email address"
            />
          </div>
          <p className="scan-email-again__text scan-email-again__text--small">
            This search is 100% private. No spam, no account required.
          </p>
          <div className="scan-email-again__buttons">
            <ButtonComponent type="submit" text="Check" mode="green" fullWidth />
          </div>
        </form>
      </div>
    </section>
  );
});

ScanEmailAgainComponent.displayName = 'ScanEmailAgainComponent';
