import {setPageData} from '@js/landing/redux/actions';
import {AppState} from '@js/landing/redux/rootReducer';
import {selectBuyNowUrl, selectIsShowPaywallPopup} from '@js/landing/redux/selectors';
import {connect} from 'react-redux';
import {PaywallPopupComponent} from './paywall-popup.component';

const mapStateToProps = (state: AppState) => ({
  isShowPaywallPopup: selectIsShowPaywallPopup(state),
  buynowUrl: selectBuyNowUrl(state),
});
const mapDispatchToProps = {
  setPageData,
};

export const PaywallPopupContainer = connect(mapStateToProps, mapDispatchToProps)(PaywallPopupComponent);
