import React from 'react';
import FacebookIcon from './images/facebook.png';
import FacebookIcon2x from './images/facebook2x.png';
import GoogleIcon from './images/google.png';
import GoogleIcon2x from './images/google2x.png';
import YoutubeIcon from './images/youtube.png';
import YoutubeIcon2x from './images/youtube2x.png';
import InstagramIcon from './images/instagram.png';
import InstagramIcon2x from './images/instagram2x.png';
import YahooIcon from './images/yahoo.png';
import YahooIcon2x from './images/yahoo2x.png';
import SkypeIcon from './images/skype.png';
import SkypeIcon2x from './images/skype2x.png';
import RedditIcon from './images/reddit.png';
import RedditIcon2x from './images/reddit2x.png';
import StackoverflowIcon from './images/stackoverflow.png';
import StackoverflowIcon2x from './images/stackoverflow2x.png';
import PinterestIcon from './images/pinterest.png';
import PinterestIcon2x from './images/pinterest2x.png';
import EyeIcon from '@common/images/icon-eye.svg';

import {DataItemProps} from './data-item.types';
import './_data-item.scss';

export const socialNetworkIcons = {
  facebook: (
    <img
      className="data-item__icon"
      src={FacebookIcon}
      alt="facebook"
      srcSet={`${FacebookIcon} 1x, ${FacebookIcon2x} 2x`}
    />
  ),
  google: (
    <img className="data-item__icon" src={GoogleIcon} alt="google" srcSet={`${GoogleIcon} 1x, ${GoogleIcon2x} 2x`} />
  ),
  youtube: (
    <img
      className="data-item__icon"
      src={YoutubeIcon}
      alt="youtube"
      srcSet={`${YoutubeIcon} 1x, ${YoutubeIcon2x} 2x`}
    />
  ),
  instagram: (
    <img
      className="data-item__icon"
      src={InstagramIcon}
      alt="instagram"
      srcSet={`${InstagramIcon} 1x, ${InstagramIcon2x} 2x`}
    />
  ),
  yahoo: <img className="data-item__icon" src={YahooIcon} alt="yahoo" srcSet={`${YahooIcon} 1x, ${YahooIcon2x} 2x`} />,
  skype: <img className="data-item__icon" src={SkypeIcon} alt="skype" srcSet={`${SkypeIcon} 1x, ${SkypeIcon2x} 2x`} />,
  reddit: (
    <img className="data-item__icon" src={RedditIcon} alt="reddit" srcSet={`${RedditIcon} 1x, ${RedditIcon2x} 2x`} />
  ),
  stackoverflow: (
    <img
      src={StackoverflowIcon}
      className="data-item__icon"
      alt="stackoverflow"
      srcSet={`${StackoverflowIcon} 1x, ${StackoverflowIcon2x} 2x`}
    />
  ),
  pinterest: (
    <img
      src={PinterestIcon}
      className="data-item__icon"
      alt="pinterest"
      srcSet={`${PinterestIcon} 1x, ${PinterestIcon2x} 2x`}
    />
  ),
};

export const DataItemComponent: React.FC<DataItemProps> = props => {
  const dataItemClasslist = ['data-item'];
  if (props.disabled) {
    dataItemClasslist.push('data-item--disabled');
  }
  const isHidden = props.hidden && FL_LANDING_PRODUCT === 'acer';
  if (isHidden) {
    dataItemClasslist.push('data-item--hidden');
  }

  return (
    <div
      className={dataItemClasslist.join(' ')}
      onClick={isHidden ? () => props.setPageData({isShowPaywallPopup: true}) : undefined}
    >
      {props.title ? <p className="data-item__title">{props.title}</p> : null}
      <p className="data-item__text">
        {props.socialIcon ? socialNetworkIcons[props.socialIcon] : null}
        {props.text}
        {isHidden ? <EyeIcon className="data-item__eye" /> : null}
      </p>
    </div>
  );
};
