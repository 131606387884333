import {ulid} from 'ulid';
import {TAPIAuthorizedRequestOptions, TAPIRequestOptions} from './types';

export const getAccessTokenFromStorage = (): string | null => {
  const accessToken = sessionStorage.getItem('accessToken');
  return accessToken;
};

export const setAccessTokenToStorage = (accessToken: string): void => {
  sessionStorage.setItem('accessToken', accessToken);
};

export const getEmailFromStorage = (): string | null => {
  const email = sessionStorage.getItem('email');
  return email;
};

export const setEmailToStorage = (email: string): void => {
  sessionStorage.setItem('email', email);
};

const getDeviceID = (): string => {
  const deviceIDFromStorage = localStorage.getItem('deviceID');
  if (deviceIDFromStorage) {
    return deviceIDFromStorage;
  }

  const deviceID = ulid();
  localStorage.setItem('deviceID', deviceID);

  return deviceID;
};

export const getRequiredRequestOptions = (): TAPIRequestOptions['headers'] => {
  return {
    'Accept-Version': 'v2',
    'X-Client-Version': '5.2.0',
    'Content-Type': 'application/json',
    'X-Device-Id': getDeviceID(),
  };
};

export const getRequiredAuthorizedRequestOptions = (): TAPIAuthorizedRequestOptions['headers'] | undefined => {
  const accessToken = getAccessTokenFromStorage();
  if (!accessToken) {
    return;
  }
  return {
    Authorization: `Bearer ${accessToken}`,
    'Accept-Version': 'v2',
    'X-Client-Version': '5.2.0',
    'Content-Type': 'application/json',
    'X-Device-Id': getDeviceID(),
  };
};
