import {TGetRiskCategoriesSource} from '@js/analytics/types';
import {GET_RISK_CATEGORIES, SET_PAGE_DATA, SET_PRESCAN_KEY, SET_RISK_CATEGORIES} from './constants';
import {PageData, TPrescanTitlesKeys, TRiskCategoriesState} from './types';

export const setPageData = (payload: Partial<PageData>) => ({
  type: SET_PAGE_DATA,
  payload,
});

export const setRiskCategories = (payload: TRiskCategoriesState) => ({
  type: SET_RISK_CATEGORIES,
  payload,
});

export const getRiskCategories = (payload: {email: string; source: TGetRiskCategoriesSource}) => ({
  type: GET_RISK_CATEGORIES,
  payload,
});

export const setPrescanKey = (prescanKey: TPrescanTitlesKeys) => ({
  type: SET_PRESCAN_KEY,
  payload: {
    prescanKey,
  },
});
