import {TRiskCategoriesState, TSetRiskCategoriesAction} from '@js/landing/redux/types';
import {RiskTypes, SET_RISK_CATEGORIES} from '@js/landing/redux/constants';

export const riskCategoriesInitialState: TRiskCategoriesState = {
  identityTheft: {
    riskType: RiskTypes.unknown,
    person: {
      images: [],
      name: '',
      gender: '',
      yearsOld: '',
      language: [],
      riskBadge: 'Publicy Available',
    },
    items: {
      ids: {
        name: 'IDs',
        riskBadge: 'Exposed',
        subItems: {
          idsSsn: {
            name: 'SSN',
            data: [],
          },
          idsDriversLicence: {
            name: 'Driver’s license',
            data: [],
          },
          idsPassport: {
            name: 'Passport',
            data: [],
          },
          idsTaxId: {
            name: 'Tax ID',
            data: [],
          },
        },
      },
      phones: {
        name: 'Phones',
        riskBadge: 'Exposed',
        data: [],
      },
      car: {
        name: 'Car',
        riskBadge: 'Publicy Available',
        data: [],
      },
      education: {
        name: 'Education',
        riskBadge: 'Publicy Available',
        data: [],
      },
      careerDetails: {
        name: 'Career details',
        riskBadge: 'Publicy Available',
        data: [],
      },
      closeConnections: {
        name: 'Close connections',
        riskBadge: 'Publicy Available',
        data: [],
      },
      socialProfiles: {
        name: 'Social profiles',
        riskBadge: 'Publicy Available',
        data: [],
      },
    },
  },
  armaniDataTrading: {
    riskType: RiskTypes.unknown,
    items: {
      generalInfo: {
        name: 'General Info',
        riskBadge: 'Exposed',
        data: [],
      },
      ids: {
        name: 'IDs',
        riskBadge: 'Exposed',
        subItems: {
          idsSsn: {
            name: 'SSN',
            data: [],
          },
          idsDriversLicence: {
            name: 'Driver’s license',
            data: [],
          },
          idsPassport: {
            name: 'Passport',
            data: [],
          },
          idsTaxId: {
            name: 'Tax ID',
            data: [],
          },
        },
      },
      phones: {
        name: 'Phones',
        riskBadge: 'Exposed',
        data: [],
      },
      socialProfiles: {
        name: 'Social profiles',
        riskBadge: 'Publicy Available',
        data: [],
      },
    },
  },
  accountHijacking: {
    riskType: RiskTypes.unknown,
    items: {
      accounts: {
        name: 'Accounts',
        riskBadge: 'Exposed',
        data: [],
      },
      logins: {
        name: 'Logins',
        riskBadge: 'Exposed',
        data: [],
      },
      passwords: {
        name: 'Passwords',
        riskBadge: 'Exposed',
        data: [],
      },
      securityQuestions: {
        name: 'Security questions',
        data: [],
        riskBadge: 'Exposed',
      },
    },
  },
  homeTitleTheft: {
    riskType: RiskTypes.unknown,
    items: {
      locations: {
        name: 'Locations',
        riskBadge: 'Exposed',
        data: [],
      },
      ipAddresses: {
        name: 'IP addresses',
        riskBadge: 'Exposed',
        data: [],
      },
    },
  },
  creditTheft: {
    riskType: RiskTypes.unknown,
    items: {
      creditCard: {
        name: 'Credit card',
        riskBadge: 'Exposed',
        subItems: {
          creditCardNumberFull: {
            name: 'Credit card number',
            data: [],
          },
          creditCardCode: {
            name: 'Credit card code',
            data: [],
          },
          creditCardExpiration: {
            name: 'Expiration date',
            data: [],
          },
        },
      },
      mortgageDetails: {
        name: 'Mortgage details',
        riskBadge: 'Exposed',
        subItems: {
          mortgageDetailsAmount: {
            name: 'Amount',
            data: [],
          },
          mortgageDetailsLenderName: {
            name: 'Lender name',
            data: [],
          },
          mortgageDetailsLoanType: {
            name: 'Loan type',
            data: [],
          },
        },
      },
      bankDetails: {
        name: 'Bank details',
        subItems: {
          bankDetailsAccountNumber: {
            name: 'Account number',
            data: [],
          },
        },
        riskBadge: 'Exposed',
      },
      creditRating: {
        name: 'Credit rating',
        riskBadge: 'Exposed',
        data: [],
      },
    },
  },
  spamRobocalls: {
    riskType: RiskTypes.unknown,
    items: {
      phones: {
        name: 'Phones',
        riskBadge: 'Exposed',
        data: [],
      },
      logins: {
        name: 'Logins',
        riskBadge: 'Exposed',
        data: [],
      },
      socialProfiles: {
        name: 'Social profiles',
        riskBadge: 'Publicy Available',
        data: [],
      },
    },
  },
  dataTrading: {
    riskType: RiskTypes.unknown,
    items: {
      spokeo: {
        name: 'spokeo',
        data: [],
        riskBadge: 'High Risk Of Trade',
      },
      peoplefinders: {
        name: 'peoplefinders',
        data: [],
        riskBadge: 'High Risk Of Trade',
      },
      peekyou: {
        name: 'peekyou',
        data: [],
        riskBadge: 'High Risk Of Trade',
      },
    },
  },
};

export const riskCategoriesReducer = (
  state: TRiskCategoriesState = riskCategoriesInitialState,
  action: TSetRiskCategoriesAction,
): TRiskCategoriesState => {
  switch (action.type) {
    case SET_RISK_CATEGORIES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
