export function handleActiveTime(payload: any): void {
  const now = new Date();
  const sessionStartTime = localStorage.getItem('activeSession');
  payload.session_time = 0;
  if (sessionStartTime) {
    const firstVisit = new Date(sessionStartTime);
    const timeDelta = Math.round((now.getTime() - firstVisit.getTime()) / 1000);
    payload.session_time = timeDelta;
  }
}
