import React, {FC, useEffect, useState} from 'react';
import './_scroll-to-top.scss';
import SrollToTopIcon from '@common/images/icon-scroll-to-top.svg';

export const ScrollToTopComponent: FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      setIsVisible(document.body.scrollTop > viewportHeight || document.documentElement.scrollTop > viewportHeight);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return isVisible ? (
    <SrollToTopIcon
      className="scroll-to-top"
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    />
  ) : null;
};
