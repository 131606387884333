export const OK_STATUS = 200;
export const CONFLICT_STATUS_CODE = 409;
export const UNKNOWN_API_ERROR = 'Oops, something went wrong';

// auth
export const SINGUP_URL = FL_API_HOST + '/public/sign-up';
export const SINGIN_URL = FL_API_HOST + '/public/sign-in';
export const PROFILE_URL = FL_API_HOST + `/auth/profile?partner=${FL_LANDING_PRODUCT}`;
export const ADD_NEW_DEVICE_URL = FL_API_HOST + '/auth/device/new';
export const CONFIRM_NEW_DEVICE_URL = FL_API_HOST + '/auth/device/confirm';

// payment
export const PAYMENT_ACCOUNT = FL_API_HOST + '/payment/account';
export const SEND_SUBSCRIPTION = FL_API_HOST + '/subscription';
