import {getRequiredAuthorizedRequestOptions, getRequiredRequestOptions} from './helpers';
import {
  RiskCategoriesResponse,
  TAPIAuthorizedRequestOptions,
  TAPIErrorResponse,
  TAPIRequestOptions,
  TAPIResponse,
  TCardData,
  TCreatePaymentAccountData,
  TUpdatePaymentAccountData,
} from './types';
import {
  ADD_NEW_DEVICE_URL,
  CONFIRM_NEW_DEVICE_URL,
  OK_STATUS,
  PROFILE_URL,
  SINGIN_URL,
  SINGUP_URL,
  PAYMENT_ACCOUNT,
  SEND_SUBSCRIPTION,
} from './constants';

export const getRiskCategories = (email: string): Promise<RiskCategoriesResponse> => {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(async () => {
      try {
        const recaptchaToken = await grecaptcha.execute(FL_LANDING_RECAPTCHA_SITEKEY, {action: 'submit'});

        const apiUrl =
          FL_LANDING_ENV === 'Dev'
            ? `${FL_LANDING_API_URL}?idg_test_key=b8a722c0-263d-45f0-85a5-3cbc412a64a0&idg_test_ip=73.171.63.11`
            : FL_LANDING_API_URL;

        const response = await fetch(apiUrl, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            recaptchaToken,
            landingID: window.localStorage.getItem('landing_id'),
            campaignID: window.localStorage.getItem('campaign_id'),
          }),
        });

        if (response.status === OK_STATUS) {
          resolve(response.json());
        } else {
          response
            .json()
            .then(res => {
              reject(res.message);
            })
            .catch(err => {
              reject(err);
            });
        }
      } catch (error) {
        reject(`error occured: ${error}`);
      }
    });
  });
};

export const requestAPI = (
  requestedURL: string,
  options: TAPIRequestOptions | TAPIAuthorizedRequestOptions,
): Promise<TAPIResponse | TAPIErrorResponse> => {
  return new Promise((resolve, reject) => {
    return fetch(requestedURL, options)
      .then(res => {
        if (res.status !== OK_STATUS) {
          res.json().then(errorResponse => reject(errorResponse));
        } else {
          res.json().then(response => resolve(response));
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const signUp = (email: string, password: string, code: string) => {
  const headers: TAPIRequestOptions['headers'] = getRequiredRequestOptions();

  const options: TAPIRequestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      email,
      password,
      code: code.length ? code : null,
    }),
  };

  return requestAPI(SINGUP_URL, options);
};

// Request account confirmation code is signup with code === null
export const requestAccountConfirmationCode = (email: string, password: string) => {
  const headers: TAPIRequestOptions['headers'] = getRequiredRequestOptions();

  const options: TAPIRequestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      email,
      password,
      code: null,
    }),
  };

  return requestAPI(SINGUP_URL, options);
};

export const signIn = (email: string, password: string) => {
  const headers: TAPIRequestOptions['headers'] = getRequiredRequestOptions();

  const options: TAPIRequestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      email,
      password,
    }),
  };

  return requestAPI(SINGIN_URL, options);
};

export const requestDeviceConfirmationCode = () => {
  const headers: TAPIAuthorizedRequestOptions['headers'] | undefined = getRequiredAuthorizedRequestOptions();
  if (!headers) {
    return Promise.reject({status: 'unauthorized'});
  }

  const options: TAPIRequestOptions = {
    method: 'POST',
    headers,
  };

  return requestAPI(ADD_NEW_DEVICE_URL, options);
};

export const confirmDevice = (code: string) => {
  const headers: TAPIAuthorizedRequestOptions['headers'] | undefined = getRequiredAuthorizedRequestOptions();
  if (!headers) {
    return Promise.reject({status: 'unauthorized'});
  }
  const options: TAPIRequestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      code,
    }),
  };

  return requestAPI(CONFIRM_NEW_DEVICE_URL, options);
};

export const getProfile = () => {
  const headers: TAPIAuthorizedRequestOptions['headers'] | undefined = getRequiredAuthorizedRequestOptions();
  if (!headers) {
    return Promise.reject({status: 'unauthorized'});
  }

  const options: TAPIRequestOptions = {
    method: 'GET',
    headers,
  };

  return requestAPI(PROFILE_URL, options);
};

export const tokenizeCard = async (cardData: TCardData) => {
  const token = await window.tokenize({
    api_gateway: FL_BILLING_API_HOST,
    api_credentials: FL_BILLING_KEY,
    card_holder: cardData.card_holder,
    card_number: cardData.card_number,
    card_expiry: cardData.card_expiry,
    card_cvcode: cardData.card_cvcode,
    addr_postal: cardData.addr_postal,
  });
  return token;
};

export const createPaymentAccount = (paymentData: TCreatePaymentAccountData) => {
  const headers: TAPIAuthorizedRequestOptions['headers'] | undefined = getRequiredAuthorizedRequestOptions();
  if (!headers) {
    return Promise.reject({status: 'unauthorized'});
  }
  const options: TAPIRequestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ...paymentData,
    }),
  };

  return requestAPI(PAYMENT_ACCOUNT, options);
};

export const updatePaymentAccount = (paymentData: TUpdatePaymentAccountData) => {
  const headers: TAPIAuthorizedRequestOptions['headers'] | undefined = getRequiredAuthorizedRequestOptions();
  if (!headers) {
    return Promise.reject({status: 'unauthorized'});
  }
  const options: TAPIRequestOptions = {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      ...paymentData,
    }),
  };

  return requestAPI(PAYMENT_ACCOUNT, options);
};

export const sendSubscription = (subscriptionData: {offer_code: string; campaign_code: string}) => {
  const headers: TAPIAuthorizedRequestOptions['headers'] | undefined = getRequiredAuthorizedRequestOptions();
  if (!headers) {
    return Promise.reject({status: 'unauthorized'});
  }
  const options: TAPIRequestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ...subscriptionData,
    }),
  };

  return requestAPI(SEND_SUBSCRIPTION, options);
};
