import {setPageData} from '@js/landing/redux/actions';
import {connect} from 'react-redux';
import {DataItemComponent} from './data-item.component';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  setPageData,
};

export const DataItemContainer = connect(mapStateToProps, mapDispatchToProps)(DataItemComponent);
