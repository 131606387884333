import {socialNetworkIcons} from '@js/landing/components/data-item/data-item.component';
import {TSocialProfileTypes} from '@js/landing/redux/types';
import React from 'react';
import {CategoryItemIconComponent} from '../../../pure-components/category-item-icon/category-item-icon.component';
import {RiskBadgeComponent} from '../../../pure-components/risk-badge/risk-badge.component';
import {DataItemContainer} from '../data-item/data-item.container';

import {Props} from './category-item.types';
import './_category-item.scss';

export const CategoryItemComponent: React.FC<Props> = props => {
  let data: Array<JSX.Element> = [];
  if (props.data) {
    data = props.data.map((dataItem, index) => {
      return <DataItemContainer key={`${props.itemKey}_${index}`} text={dataItem.value} hidden={dataItem.hidden} />;
    });
  } else {
    const socialNetworks = Object.keys(socialNetworkIcons) as Array<TSocialProfileTypes>;
    socialNetworks.forEach(socialNetwork => {
      if (props.socialProfilesData) {
        const leakedProfiles = props.socialProfilesData[socialNetwork];
        if (leakedProfiles?.length) {
          leakedProfiles.forEach((profile, index) => {
            data.push(
              <DataItemContainer key={`${socialNetwork}_${index}`} text={profile.value} socialIcon={socialNetwork} />,
            );
          });
        }
      }
    });
  }

  const isDataAvailable = Boolean(data.length);

  if (!isDataAvailable) {
    if (props.isDataFetched) {
      data.push(<DataItemContainer key={`${props.itemKey}_na`} disabled text={`${props.itemName} not found`} />);
    } else {
      data.push(<DataItemContainer key={`${props.itemKey}_na`} disabled text="N/A" />);
    }
  }

  return (
    <div className="category-item">
      <div className="category-item__name">
        <CategoryItemIconComponent itemName={props.itemKey} />
        <h6 className="category-item__title">{props.itemName}</h6>
      </div>
      <div className="category-item__data">{data}</div>
      <div className="category-item__badge">
        {isDataAvailable ? <RiskBadgeComponent text={props.riskBadge} riskType={props.riskType} /> : null}
      </div>
    </div>
  );
};
