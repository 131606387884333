import React from 'react';
import {CategoryItemComponent} from '@js/landing/components/category-item/category-item.component';
import {CategoryPersonItemComponent} from '@js/landing/components/category-person-item/category-person-item.component';
import {CategoryItemWithSubitemsComponent} from '@js/landing/components/category-item-with-subitems/category-item-with-subitems.component';
import {ButtonComponent} from '@js/pure-components/button/button.component';
import {CategoryProps} from './category.types';
import './_category';
import {sendAnalytics} from '@js/analytics/sendAnalytics';
import {TPersonRiskCategoryItem, TRiskCategoriesState, TRiskCategoryItem} from '@js/landing/redux/types';
import {PrescanTitles, RiskTypes} from '@js/landing/redux/constants';
import {TooltipComponent} from '@js/pure-components/tooltip/tooltip.component';
import {categoriesConfig} from './config';
import {FigleafGradientLogoComponent} from '@js/pure-components/fl-gradient-logo/fl-gradient-logo.component';

export const CategoryComponent: React.FC<CategoryProps> = props => {
  const category = props.riskCategories[props.categoryName];
  if (!category) {
    return null;
  }
  const {items, riskType} = category;

  const categoryItems = Object.entries(items).map(([key, value]) => {
    const item = value as TRiskCategoryItem;
    if (key === 'person') {
      const personData = value as TPersonRiskCategoryItem;
      return <CategoryPersonItemComponent isDataFetched={props.isDataFetched} key={key} personData={personData} />;
    }
    if (item.subItems) {
      return (
        <CategoryItemWithSubitemsComponent
          key={key}
          itemKey={key}
          isDataFetched={props.isDataFetched}
          itemName={item.name}
          riskBadge={item.riskBadge}
          subItems={item.subItems}
        />
      );
    }

    const data = item.data as Array<{value: string; hidden?: boolean}>;
    return (
      <CategoryItemComponent
        key={key}
        itemKey={key}
        isDataFetched={props.isDataFetched}
        itemName={item.name}
        riskBadge={item.riskBadge}
        data={data}
        socialProfilesData={item.socialProfilesData}
        riskType={category.riskType}
      />
    );
  });

  // TODO: remove this hack
  if (props.categoryName === 'identityTheft') {
    const identityTheftCategory = category as TRiskCategoriesState['identityTheft'];
    categoryItems.unshift(
      <CategoryPersonItemComponent
        isDataFetched={props.isDataFetched}
        key="identityTheftPerson"
        personData={identityTheftCategory.person}
      />,
    );
  }

  const categoryClassname = ['category'];
  const categoryRiskTypeClassname = ['category__risk-type'];
  let riskTypeText = 'Unknown';
  if (riskType === RiskTypes.high) {
    categoryClassname.push('category--high-risk');
    categoryRiskTypeClassname.push('category__risk-type--high');
    riskTypeText = 'High Risk';
  }
  if (riskType === RiskTypes.moderate) {
    categoryClassname.push('category--moderate-risk');
    categoryRiskTypeClassname.push('category__risk-type--moderate');
    riskTypeText = 'Moderate Risk';
  }
  if (riskType === RiskTypes.low) {
    categoryClassname.push('category--low-risk');
    categoryRiskTypeClassname.push('category__risk-type--low');
    riskTypeText = 'Low Risk';
  }

  const unknownStateButtonText = FL_LANDING_PRODUCT === 'acer' ? 'Check My Exposure' : 'Free Checkup';
  let buttonText = unknownStateButtonText;
  let descriptionText: string = categoriesConfig[props.categoryName].description;

  if (props.isDataFetched) {
    if (props.globalRiskType === RiskTypes.low) {
      buttonText = categoriesConfig[props.categoryName].buttonKeepText;

      if (FL_LANDING_PRODUCT === 'acer' && categoriesConfig[props.categoryName].descriptionKeep) {
        descriptionText = categoriesConfig[props.categoryName].descriptionKeep as string;
      }
    } else {
      buttonText = categoriesConfig[props.categoryName].buttonProtectText;
    }
  }

  return (
    <div className={categoryClassname.join(' ')} id={props.categoryName}>
      <div className="category__inner">
        <div
          className={FL_LANDING_PRODUCT !== 'acer' ? 'category__header category__header--direct' : 'category__header'}
        >
          <div className="category__header-col">
            <span className="category__part-number">{`Part ${props.partNumber} of ${props.partsCount}`}</span>
            <h3 className="category__title">{categoriesConfig[props.categoryName].title}</h3>
            <span className={categoryRiskTypeClassname.join(' ')}>{riskTypeText}</span>
          </div>
          <div className="category__header-col">
            <div className="category__description">
              {props.categoryName === 'accountHijacking' && (
                <span className="category__description-hint">
                  <TooltipComponent
                    modifier="dark"
                    text={`Risk Based Security\n”2019 Year End Breach Quick View Report.” \nFebruary 2020`}
                    display="inline-block"
                  >
                    <span>More than 15 billion records were exposed globally in company data breaches in 2019.</span>
                  </TooltipComponent>
                </span>
              )}
              <span>{descriptionText}</span>
            </div>
          </div>
        </div>
        <div className="category__content">{categoryItems}</div>
        {props.categoryName === 'dataTrading' && <p className="category__data-trading-text">+ 18 other data brokers</p>}
        {!props.isDataFetched && (
          <div className="category__button">
            <ButtonComponent
              onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
              text={buttonText}
              sizeModifier="sm"
              mode="green"
              disabled={props.isLoading}
              fullWidth
            />
          </div>
        )}
      </div>
      {props.isDataFetched && (
        <div className="category__buynow-section">
          <div className="category__buynow-logo">
            <FigleafGradientLogoComponent />
          </div>
          <h3 className="category__buynow-title">{PrescanTitles[props.prescanKey]}</h3>
          <ButtonComponent
            onClick={() => {
              sendAnalytics({
                eventName: 'click_on_buy_now_button',
                payload: {
                  location: props.categoryName,
                },
              });
              window.location.href = props.buyNowUrl;
            }}
            text="Activate with 67% Discount"
            sizeModifier="sm"
            mode="green"
            disabled={props.isLoading}
            fullWidth
          />
        </div>
      )}
    </div>
  );
};
