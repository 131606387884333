import {RiskTypes} from '@js/landing/redux/constants';
import React from 'react';
import {Props} from './risk-badge.types';
import './_risk-badge.scss';

const exposedRiskTypes = [RiskTypes.moderate, RiskTypes.high];

export const RiskBadgeComponent: React.FC<Props> = ({text, riskType}) => {
  const className = `risk-badge${riskType && exposedRiskTypes.indexOf(riskType) > -1 ? '' : ' risk-badge--low'}`;
  return <div className={className}>{text}</div>;
};
