import React, {memo} from 'react';
import './_error-popup.scss';
import {ErrorPopupProps} from './error-popup.types';
import {ButtonComponent} from '@js/pure-components/button/button.component';
import ExclamationIcon from '@common/images/exclamation.svg';

export const ErrorPopupComponent = memo<ErrorPopupProps>(({setPageData, isShowErrorPopup}) => {
  if (!isShowErrorPopup) {
    return null;
  }
  return (
    <div className="error-popup__overlay">
      <div className="error-popup__body">
        <ExclamationIcon />
        <h1 className="error-popup__title">Your request cannot be satisfied right now. Try again later.</h1>
        <div className="error-popup__button">
          <ButtonComponent
            text="OK"
            onClick={() => {
              setPageData({isShowErrorPopup: false});
            }}
            sizeModifier={'sm'}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
});

ErrorPopupComponent.displayName = 'ErrorPopupComponent';
