import {RiskTypes} from '@js/landing/redux/constants';
import React from 'react';
import {PersonImagesComponent} from '../../../pure-components/person-images/person-images.component';
import {RiskBadgeComponent} from '../../../pure-components/risk-badge/risk-badge.component';
import {DataItemContainer} from '../data-item/data-item.container';

import {Props} from './category-person-item.types';
import './_category-person-item.scss';

export const CategoryPersonItemComponent: React.FC<Props> = props => {
  const {gender, yearsOld, name, images, language, riskBadge} = props.personData;
  const isDataAvailable = Boolean(gender.length || yearsOld.length || language.length || name);

  const prettifiedLanguages = language.length ? `Speaks ${language.map(item => item.value).join(', ')}` : '';

  const data = [gender, yearsOld, prettifiedLanguages].map((dataItem, index) => {
    if (dataItem.length) {
      return <DataItemContainer key={`person_${index}`} text={dataItem} />;
    }
    return null;
  });

  let fullNameText = name;

  if (!isDataAvailable) {
    if (props.isDataFetched) {
      data.push(<DataItemContainer key={`$person_na`} disabled text="General info not found" />);
      fullNameText = 'Name Not Found';
    } else {
      data.push(<DataItemContainer key={`$person_na`} disabled text="N/A" />);
      fullNameText = 'Name & General Info';
    }
  }

  return (
    <div className="category-person-item">
      <div className="category-person-item__image">
        <PersonImagesComponent images={images} />
      </div>
      <h4 className={`category-person-item__name${!name.length ? ' category-person-item__name--disabled' : ''}`}>
        {fullNameText}
      </h4>
      <div className="category-person-item__data">{data}</div>
      <div className="category-person-item__badge">
        {isDataAvailable ? <RiskBadgeComponent riskType={RiskTypes.high} text={riskBadge} /> : null}
      </div>
    </div>
  );
};
