import React from 'react';
import {Props} from './category-item-icon.types';

import AccountIcon from '@common/images/icon-account.svg';
import BankIcon from '@common/images/icon-bank.svg';
import CarIcon from '@common/images/icon-car.svg';
import CardIcon from '@common/images/icon-card.svg';
import CareerIcon from '@common/images/icon-career.svg';
import ConnectionsIcon from '@common/images/icon-connections.svg';
import EducationIcon from '@common/images/icon-education.svg';
import IdIcon from '@common/images/icon-id.svg';
import ProfileIcon from '@common/images/icon-profile.svg';
import IpIcon from '@common/images/icon-ip.svg';
import LinkIcon from '@common/images/icon-link.svg';
import MortgageIcon from '@common/images/icon-mortgage.svg';
import LocationIcon from '@common/images/icon-location.svg';
import LoginIcon from '@common/images/icon-login.svg';
import PasswordIcon from '@common/images/icon-password.svg';
import PhoneIcon from '@common/images/icon-phone.svg';
import QuestionIcon from '@common/images/icon-question.svg';
import RatingIcon from '@common/images/icon-rating.svg';
import SpokeoIcon from '@common/images/icon-spokeo.svg';
import PeoplefindersIcon from '@common/images/icon-peoplefinders.svg';
import PeekyouIcon from '@common/images/icon-peekyou.svg';

const DEFAULT_ICON = <AccountIcon />;

const ICONS: {[key in string]: JSX.Element} = {
  ids: <IdIcon />,
  phones: <PhoneIcon />,
  car: <CarIcon />,
  education: <EducationIcon />,
  careerDetails: <CareerIcon />,
  closeConnections: <ConnectionsIcon />,
  socialProfiles: <LinkIcon />,
  accounts: <AccountIcon />,
  generalInfo: <ProfileIcon />,
  logins: <LoginIcon />,
  passwords: <PasswordIcon />,
  securityQuestions: <QuestionIcon />,
  locations: <LocationIcon />,
  ipAddresses: <IpIcon />,
  creditCard: <CardIcon />,
  mortgageDetails: <MortgageIcon />,
  bankDetails: <BankIcon />,
  creditRating: <RatingIcon />,
  spokeo: <SpokeoIcon />,
  peoplefinders: <PeoplefindersIcon />,
  peekyou: <PeekyouIcon />,
};

// TODO: handle social profile images usin our images API
export const CategoryItemIconComponent: React.FC<Props> = props => {
  if (ICONS[props.itemName]) {
    return ICONS[props.itemName];
  }

  return DEFAULT_ICON;
};
