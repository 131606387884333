import {setPageData} from '@js/landing/redux/actions';
import {selectIsShowErrorPopup} from '@js/landing/redux/selectors';
import {AppState} from '@js/landing/redux/rootReducer';
import {connect} from 'react-redux';
import {ErrorPopupComponent} from './error-popup.component';

const mapStateToProps = (state: AppState) => ({
  isShowErrorPopup: selectIsShowErrorPopup(state),
});
const mapDispatchToProps = {
  setPageData,
};

export const ErrorPopupContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorPopupComponent);
