import React, {memo} from 'react';
import './_paywall-popup.scss';
import {PaywallPopupProps} from './paywall-popup.types';
import {ButtonComponent} from '@js/pure-components/button/button.component';
import {FigleafGradientLogoComponent} from '@js/pure-components/fl-gradient-logo/fl-gradient-logo.component';
import {sendAnalytics} from '@js/analytics/sendAnalytics';
import {DiscountBadgeComponent} from '@js/pure-components/discount-badge/discount-badge.component';
import IconAcer from '@common/images/icon-acer-current-color.svg';
import IconCheckmarkCurrentColor from '@common/images/icon-checkmark-current-color.svg';
import IconClose from '@common/images/icon-close.svg';

const features = [
  'See sensitive exposed data',
  'Prevent accounts exposure',
  'Monitor your Data Trading risk level',
  'Eliminate location exposure',
];

export const PaywallPopupComponent = memo<PaywallPopupProps>(({setPageData, isShowPaywallPopup, buynowUrl}) => {
  if (!isShowPaywallPopup) {
    return null;
  }
  return (
    <div className="paywall-popup__overlay">
      <div className="paywall-popup__body">
        <IconClose className="paywall-popup__close" onClick={() => setPageData({isShowPaywallPopup: false})} />
        <FigleafGradientLogoComponent />
        <h1 className="paywall-popup__title">Get FigLeaf Premium</h1>
        <h1 className="paywall-popup__subtitle">Access the Full Report & Eliminate Privacy Risks</h1>
        <div className="paywall-popup__separator"></div>

        <h4 className="paywall-popup__features-title">Subscribe today and:</h4>
        <div className="paywall-popup__features">
          {features.map((item, index) => (
            <div className="paywall-popup__feature" key={index}>
              <IconCheckmarkCurrentColor className="paywall-popup__feature-icon" /> {item}
            </div>
          ))}
        </div>

        <DiscountBadgeComponent>
          Get special price for&nbsp;
          <IconAcer className="paywall-popup__discount-badge-logo" />
          &nbsp;customers only!
        </DiscountBadgeComponent>

        <div className="paywall-popup__button">
          <ButtonComponent
            text="Protect My Privacy"
            onClick={() => {
              sendAnalytics({
                eventName: 'click_on_buy_now_button',
                payload: {
                  location: 'paywall_popup',
                },
              });
              window.location.href = buynowUrl;
            }}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
});

PaywallPopupComponent.displayName = 'PaywallPopupComponent';
