import {getRiskCategories} from '@js/landing/redux/actions';
import {
  selectAvailableCategoryTypes,
  selectBuyNowUrl,
  selectEmail,
  selectExposedItemsCount,
  selectGlobalRiskType,
  selectIsDataFetched,
  selectIsLoading,
  selectRiskCategories,
} from '@js/landing/redux/selectors';
import {AppState} from '@js/landing/redux/rootReducer';
import {connect} from 'react-redux';
import {Header} from './header.component';

const mapStateToProps = (state: AppState) => ({
  isDataFetched: selectIsDataFetched(state),
  isLoading: selectIsLoading(state),
  globalRiskType: selectGlobalRiskType(state),
  exposedItemsCount: selectExposedItemsCount(state),
  availableCategoryTypes: selectAvailableCategoryTypes(state) || [],
  riskCategories: selectRiskCategories(state),
  email: selectEmail(state),
  buyNowUrl: selectBuyNowUrl(state),
});

const mapDispatchToProps = {
  getRiskCategories,
};

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
