export const sendPageEventGTM = (page: string, eventName: string) => {
  if (FL_LANDING_ENV !== 'Prod' || FL_LANDING_PRODUCT !== 'figleaf') {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'gtm.newHistoryState': page, event: eventName});
};

export const sendClickEventGTM = (event: string) => {
  if (FL_LANDING_ENV !== 'Prod' || FL_LANDING_PRODUCT !== 'figleaf') {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({_event: 'gtm.click', event});
};
