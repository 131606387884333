import React, {FC} from 'react';
import {Props} from './spinner.types';
import SpinnerIcon from './images/path-file/spinner.png';
import SpinnerIconGreen from './images/path-file/spinner-green.png';
import './_spinner.scss';

export const Spinner: FC<Props> = props => {
  const classlist = ['spinner'];
  if (props.sizeModifier) {
    classlist.push(`spinner--${props.sizeModifier}`);
  }
  return (
    <div className="spinner__wrapper">
      <div className={classlist.join(' ')}>
        {props.mode === 'green' ? <img src={SpinnerIconGreen} alt="..." /> : <img src={SpinnerIcon} alt="..." />}
      </div>
      {Boolean(props.text) && <span className="spinner__text">{props.text}</span>}
    </div>
  );
};
