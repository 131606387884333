import {AppState} from '@js/landing/redux/rootReducer';

export const selectIsDataFetched = (state: AppState) => state.pageState.pageData.isDataFetched;
export const selectIsShowErrorPopup = (state: AppState) => state.pageState.pageData.isShowErrorPopup;
export const selectIsShowPaywallPopup = (state: AppState) => state.pageState.pageData.isShowPaywallPopup;
export const selectGlobalRiskType = (state: AppState) => state.pageState.pageData.globalRiskType;
export const selectBuyNowUrl = (state: AppState) => state.pageState.pageData.buyNowUrl;
export const selectRiskCategories = (state: AppState) => state.pageState.riskCategories;
export const selectEmail = (state: AppState) => state.pageState.pageData.email;
export const selectIsLoading = (state: AppState) => state.pageState.pageData.isLoading;
export const selectExposedItemsCount = (state: AppState) => state.pageState.pageData.exposedItemsCount;
export const selectAvailableCategoryTypes = (state: AppState) => state.pageState.pageData.availableCategoryTypes;
export const selectPrescanKey = (state: AppState) => state.pageState.pageData.prescanKey;
