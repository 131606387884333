export enum RiskTypes {
  unknown,
  low,
  moderate,
  high,
}

export const PrescanTitles = {
  see: 'Get FigLeaf to control private data exposure',
  spam: 'Get FigLeaf to stop Robocalls & Spam',
  image: 'Get FigLeaf to delete your data from web',
  bank: 'Get FigLeaf to protect from financial fraud',
  email: 'Get FigLeaf to secure your online accounts',
};

export const SET_PAGE_DATA: 'SET_PAGE_DATA' = 'SET_PAGE_DATA';

export const GET_RISK_CATEGORIES: 'GET_RISK_CATEGORIES' = 'GET_RISK_CATEGORIES';
export const SET_RISK_CATEGORIES: 'SET_RISK_CATEGORIES' = 'SET_RISK_CATEGORIES';

export const SET_PRESCAN_KEY: 'SET_PRESCAN_KEY' = 'SET_PRESCAN_KEY';
