import {TParsedDataKeys, TParsedDataState} from '@js/landing/redux/types';
import {DateRange, PiplName, RiskCategoriesResponse} from './types';

const getReadableDateRange = (dateRange?: DateRange): string | false => {
  if (!dateRange) {
    return false;
  } else {
    const dateRangeParts = [];
    const dateFormatOptions = {year: 'numeric', month: 'short'};
    dateRange.start && dateRangeParts.push(new Date(dateRange.start).toLocaleDateString('en-US', dateFormatOptions));
    dateRange.end && dateRangeParts.push(new Date(dateRange.end).toLocaleDateString('en-US', dateFormatOptions));
    return dateRangeParts.length ? dateRangeParts.join(' - ') : false;
  }
};

const getPiplName = (name: PiplName) => {
  if (name.raw) {
    return name.raw;
  } else {
    const parts = [];
    name.first && parts.push(name.first);
    name.middle && parts.push(name.middle);
    name.last && parts.push(name.last);
    return parts.join(' ');
  }
};

export const getDataTradingData = (parsedData: TParsedDataState): Array<{value: string; hidden?: boolean}> | false => {
  const data = [];
  parsedData.names.length && data.push(parsedData.names[0]);
  if (parsedData.logins.length) {
    const randomIndex = Math.floor(Math.random() * parsedData.logins.length);
    data.push(parsedData.logins[randomIndex]);
  }
  if (parsedData.phones.length) {
    const randomIndex = Math.floor(Math.random() * parsedData.phones.length);
    data.push(parsedData.phones[randomIndex]);
  }
  return data.length ? data : false;
};

const unknownSites = ['n/a', 'na', 'n/a/', 'unknown'];

export const getParsedData = (response: RiskCategoriesResponse) => {
  const {leaks, person} = response;
  const parsedData: TParsedDataState = {
    images: [],
    names: [],
    gender: [],
    language: [],
    accounts: [],
    phones: [],
    socialProfiles: [],
    socialFacebook: [],
    socialGoogle: [],
    socialYoutube: [],
    socialInstagram: [],
    socialYahoo: [],
    socialSkype: [],
    socialReddit: [],
    socialStackoverflow: [],
    socialPinterest: [],
    logins: [],
    passwords: [],
    ipAddresses: [],
    locations: [],
    car: [],
    education: [],
    careerDetails: [],
    closeConnections: [],
    ids: [],
    idsSsn: [],
    idsDriversLicence: [],
    idsPassport: [],
    idsTaxId: [],
    creditCard: [],
    creditCardNumberFull: [],
    creditCardNumberLastFour: [],
    creditCardCode: [],
    creditCardExpiration: [],
    creditRating: [],
    mortgageDetails: [],
    mortgageDetailsAmount: [],
    mortgageDetailsLenderName: [],
    mortgageDetailsLoanType: [],
    bankDetails: [],
    bankDetailsAccountNumber: [],
    securityQuestions: [],
    generalInfo: [],
    yearsOld: [],
  };

  if (leaks) {
    leaks.forEach(leak => {
      leak.assets.forEach(darkNetLeak => {
        if (darkNetLeak.email || darkNetLeak.username) {
          if (leak.site && unknownSites.indexOf(leak.site) < 0) {
            parsedData.accounts.push({value: leak.site});
          }
        }

        const dataForPush = {
          logins: [{value: darkNetLeak.email}, {value: darkNetLeak.username}],
          phones: [{value: darkNetLeak.phone, hidden: true}],
          creditRating: [{value: darkNetLeak.credit_rating}],
          bankDetailsAccountNumber: [{value: darkNetLeak.bank_number}],
          securityQuestions: [{value: darkNetLeak.account_secret_question, hidden: true}],
          passwords: [{value: darkNetLeak.password_plaintext, hidden: true}],
          names: [{value: darkNetLeak.full_name}],

          // mortgage details
          mortgageDetailsAmount: [{value: darkNetLeak.mortgage_amount}],
          mortgageDetailsLenderName: [{value: darkNetLeak.mortgage_lender_name}],
          mortgageDetailsLoanType: [{value: darkNetLeak.mortgage_loan_type}],

          // credit card
          creditCardCode: [{value: darkNetLeak.cc_code}],
          creditCardNumberFull: [{value: darkNetLeak.cc_number}],
          creditCardNumberLastFour: [{value: darkNetLeak.cc_last_four}],
          creditCardExpiration: [{value: darkNetLeak.cc_expiration}],

          // locations
          locations: [{value: darkNetLeak.address_1}, {value: darkNetLeak.address_2}],

          // ids
          idsSsn: [{value: darkNetLeak.social_security_number, hidden: true}],
          idsDriversLicence: [],
          idsPassport: [{value: darkNetLeak.national_id, hidden: true}],
          idsTaxId: [{value: darkNetLeak.taxid, hidden: true}],
        };

        const dataForConcat = {
          ipAddresses: [darkNetLeak.ip_addresses],
          // social
          socialFacebook: [darkNetLeak.social_facebook],
          socialGoogle: [darkNetLeak.social_google],
          socialYoutube: [darkNetLeak.social_youtube],
          socialInstagram: [darkNetLeak.social_instagram],
          socialYahoo: [darkNetLeak.social_yahoo],
          socialSkype: [darkNetLeak.social_skype],
          socialReddit: [darkNetLeak.social_reddit],
          socialStackoverflow: [darkNetLeak.social_stackoverflow],
          socialPinterest: [darkNetLeak.social_pinterest],
        };

        Object.entries(dataForPush).forEach(([key, values]) => {
          values.forEach((val: {value?: string}) => {
            if (val && val.value) {
              parsedData[key as TParsedDataKeys].push(val as {value: string; hidden: boolean});
            }
          });
        });
        Object.entries(dataForConcat).forEach(([key, values]) => {
          values.forEach(val => {
            if (val) {
              parsedData[key as TParsedDataKeys] = [
                ...parsedData[key as TParsedDataKeys],
                ...val.map(item => ({
                  value: item,
                })),
              ];
            }
          });
        });
      });
    });

    if (person) {
      if (person.addresses) {
        person.addresses.forEach(address => {
          if (address.raw) {
            parsedData.locations.push({value: address.raw});
          } else if (address.display) {
            parsedData.locations.push({value: address.display});
          } else {
            const parts = [];

            address.country && parts.push(address.country);
            address.state && parts.push(address.state);
            address.city && parts.push(address.city);
            address.street && parts.push(address.street);
            address.house && parts.push(address.house);
            address.apartment && parts.push(address.apartment);
            address.po_box && parts.push(address.po_box);
            address.zip_code && parts.push(address.zip_code);

            if (parts.length) {
              parsedData.locations.push({value: parts.join(', ')});
            }
          }
        });
      }

      if (person.educations)
        person.educations.forEach(edu => {
          const parts = [];
          edu.school && parts.push(edu.school);

          const readableDateRange = getReadableDateRange(edu.date_range);
          readableDateRange && parts.push(readableDateRange);

          edu.degree && parts.push(edu.degree);
          if (parts.length) {
            parsedData.education.push({value: parts.join(', ')});
          }
        });

      if (person.emails) {
        person.emails.forEach(email => {
          email.address && parsedData.logins.push({value: email.address});
        });
      }

      if (person.usernames) {
        person.usernames.forEach(username => {
          username.content && parsedData.logins.push({value: username.content});
        });
      }

      if (person.phones) {
        person.phones.forEach(phone => {
          if (phone.raw) {
            parsedData.phones.push({value: phone.raw, hidden: true});
          } else if (phone.display) {
            parsedData.phones.push({value: phone.display, hidden: true});
          } else if (phone.display_international) {
            parsedData.phones.push({value: phone.display_international, hidden: true});
          } else if (phone.number) {
            parsedData.phones.push({value: phone.number, hidden: true});
          }
        });
      }

      if (person.images) {
        person.images.forEach(image => {
          image.url && parsedData.images.push({value: image.url});
        });
      }

      if (person.names) {
        person.names.forEach(name => {
          const formattedName = getPiplName(name);
          if (formattedName) {
            parsedData.names.push({value: formattedName});
          }
        });
      }

      if (person.jobs) {
        person.jobs.forEach(job => {
          const parts = [];
          job.title && parts.push(job.title);
          job.organization && parts.push(`${job.organization}`);
          if (parts.length) {
            parsedData.careerDetails.push({value: parts.join(' at ')});
          }
        });
      }

      if (person.relationships) {
        person.relationships.forEach(relatedPerson => {
          if (relatedPerson.names) {
            relatedPerson.names.forEach(name => {
              const connectionName = getPiplName(name);
              if (connectionName) {
                parsedData.closeConnections.push({value: connectionName});
              }
            });
          }
        });
      }

      if (person.gender) {
        parsedData.gender.push({value: person.gender.content});
      }

      if (person.dob && person.dob.display) {
        parsedData.yearsOld.push({value: person.dob.display});
      }
    }
  }

  Object.keys(parsedData).forEach(key => {
    parsedData[key as TParsedDataKeys] = Array.from(
      new Set(parsedData[key as TParsedDataKeys].map(item => JSON.stringify(item))),
    )
      .map(item => JSON.parse(item))
      .sort(
        (a, b) => b.value.length - a.value.length, // to have consistent first items
      );
  });

  return parsedData;
};
