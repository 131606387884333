import React from 'react';
import {FigleafGradientLogoComponent} from '@js/pure-components/fl-gradient-logo/fl-gradient-logo.component';
import IconCheckSmall from '@common/images/icon-check-small.svg';
import {ButtonSpecialComponent} from '@js/pure-components/button-special/button-special.component';
import {PrescanTitles, RiskTypes} from '@js/landing/redux/constants';

import {DownloadSectionProps} from './download-section.types';
import './_download-section.scss';
import {sendAnalytics} from '@js/analytics/sendAnalytics';
import {sendClickEventGTM} from '@js/analytics/eventsGTM';

export const DownloadSectionComponent: React.FC<DownloadSectionProps> = ({titleKey, riskType, buyNowUrl}) => {
  const openBuynow = () => {
    sendAnalytics({
      eventName: 'click_on_buy_now_button',
      payload: {
        location: 'download-section',
      },
    });

    window.location.href = buyNowUrl;
  };

  const downloadApp = () => {
    sendAnalytics({
      eventName: 'download_button_click',
      payload: {location: 'download-section'},
    });

    sendClickEventGTM('Prescan_Download');

    window.open(FL_DOWNLOAD_URL, '_blank');
  };

  return (
    <div className="download-section">
      <div className="download-section__inner">
        <div className="download-section__logo">
          <FigleafGradientLogoComponent />
        </div>
        <div className="download-section__content">
          <h3 className="download-section__title">{PrescanTitles[titleKey]}</h3>
          <div className="download-section__benefits">
            <div className="download-section__benefits-item">
              <div className="download-section__benefits-icon">
                <IconCheckSmall />
              </div>
              Monitor data leaks
            </div>
            <div className="download-section__benefits-item">
              <div className="download-section__benefits-icon">
                <IconCheckSmall />
              </div>
              Prevent data collection
            </div>
            <div className="download-section__benefits-item">
              <div className="download-section__benefits-icon">
                <IconCheckSmall />
              </div>
              Protect your accounts
            </div>
          </div>
        </div>
        <div className="download-section__buttons">
          <div className={`download-section__arrow download-section__arrow--${RiskTypes[riskType]}`} />
          <ButtonSpecialComponent text="Activate with 67% Discount" onClick={openBuynow} />
          <button type="button" onClick={downloadApp} className="download-section__download-button">
            or Start your 7-day FREE trial
          </button>
        </div>
      </div>
    </div>
  );
};
