import {connect} from 'react-redux';
import {getRiskCategories} from '@js/landing/redux/actions';
import {ScanEmailAgainComponent} from './scan-email-again.component';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  getRiskCategories,
};

export const ScanEmailAgainContainer = connect(mapStateToProps, mapDispatchToProps)(ScanEmailAgainComponent);
