import {createStore, applyMiddleware, Middleware} from 'redux';
import {createLogger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import {rootReducer} from './rootReducer';
import {rootSaga} from './rootSaga';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger({
  collapsed: true,
});

const middlewares: Array<Middleware<any, any, any>> = [sagaMiddleware];

if (FL_LANDING_ENV === 'Dev') {
  middlewares.push(loggerMiddleware);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);
