import {call, put, select, takeEvery} from 'redux-saga/effects';
import {getRiskCategories} from '@js/api';
import {setPageData, setRiskCategories} from './actions';
import {GET_RISK_CATEGORIES, RiskTypes} from './constants';
import {
  TGetRiskCategoriesAction,
  TParsedDataKeys,
  TRiskCategoriesState,
  TRiskCategoryType,
  TSocialProfiles,
} from './types';
import {RiskCategoriesResponse} from '@js/api/types';
import {sendAnalytics} from '@js/analytics/sendAnalytics';
import {selectAvailableCategoryTypes, selectIsDataFetched} from './selectors';
import {riskCategoriesInitialState} from './reducers/risk-categories.reducer';
import {getDataTradingData, getParsedData} from '@js/api/parsers';
import {sendPageEventGTM} from '@js/analytics/eventsGTM';

export function* getRiskCategoriesSaga(action: TGetRiskCategoriesAction) {
  const isDataFetched = yield select(selectIsDataFetched);

  if (isDataFetched) {
    // reset state to default if data was fetched before
    yield put(setRiskCategories(riskCategoriesInitialState));
  }

  yield put(
    setPageData({
      isLoading: true,
      isDataFetched: false,
      email: action.payload.email,
      globalRiskType: isDataFetched ? RiskTypes.unknown : undefined,
    }),
  );

  sendAnalytics({
    eventName: 'scan_start',
    payload: {source: action.payload.source},
  });
  try {
    const response: RiskCategoriesResponse = yield call(getRiskCategories, action.payload.email);

    sendAnalytics({
      eventName: 'scan_finish',
      payload: {full_result: Boolean(response.person)},
    });
    const parsedData = getParsedData(response);

    const parsedDataKeys = Object.keys(parsedData) as Array<TParsedDataKeys>;
    let exposedItemsCount = 0;
    parsedDataKeys.forEach(key => (exposedItemsCount += parsedData[key].length));

    let identityTheftRiskType = RiskTypes.unknown;
    if (
      parsedData.idsDriversLicence.length ||
      parsedData.idsPassport.length ||
      parsedData.idsSsn.length ||
      parsedData.idsTaxId.length ||
      parsedData.phones.length
    ) {
      identityTheftRiskType = RiskTypes.high;
    } else if (
      parsedData.education.length ||
      parsedData.car.length ||
      parsedData.careerDetails.length ||
      parsedData.closeConnections.length ||
      parsedData.socialFacebook.length ||
      parsedData.socialGoogle.length ||
      parsedData.socialYoutube.length ||
      parsedData.socialInstagram.length ||
      parsedData.socialYahoo.length ||
      parsedData.socialSkype.length ||
      parsedData.socialReddit.length ||
      parsedData.socialStackoverflow.length ||
      parsedData.socialPinterest.length ||
      parsedData.names.length
    ) {
      identityTheftRiskType = RiskTypes.moderate;
    } else {
      identityTheftRiskType = RiskTypes.low;
    }

    let accountHijackingRiskType = RiskTypes.unknown;
    if (parsedData.passwords.length || parsedData.securityQuestions.length) {
      accountHijackingRiskType = RiskTypes.high;
    } else if (parsedData.logins.length || parsedData.passwords.length) {
      accountHijackingRiskType = RiskTypes.moderate;
    } else {
      accountHijackingRiskType = RiskTypes.low;
    }

    let creditTheftRiskType = RiskTypes.unknown;
    if (parsedData.creditCardNumberFull.length || parsedData.bankDetailsAccountNumber.length) {
      creditTheftRiskType = RiskTypes.high;
    } else if (
      parsedData.creditCardNumberLastFour.length ||
      parsedData.mortgageDetailsAmount.length ||
      parsedData.mortgageDetailsLenderName.length ||
      parsedData.mortgageDetailsLoanType.length
    ) {
      creditTheftRiskType = RiskTypes.moderate;
    } else {
      creditTheftRiskType = RiskTypes.low;
    }

    let spamRobocallsRiskType = RiskTypes.unknown;
    if (parsedData.phones.length) {
      spamRobocallsRiskType = RiskTypes.high;
    } else if (parsedData.logins.length) {
      spamRobocallsRiskType = RiskTypes.moderate;
    } else {
      spamRobocallsRiskType = RiskTypes.low;
    }

    let homeTitleTheftRiskType = RiskTypes.unknown;
    if (parsedData.locations.length) {
      homeTitleTheftRiskType = RiskTypes.high;
    } else if (parsedData.ipAddresses.length) {
      homeTitleTheftRiskType = RiskTypes.moderate;
    } else {
      homeTitleTheftRiskType = RiskTypes.low;
    }

    const isEveryCategoryLowRisk = [
      identityTheftRiskType,
      accountHijackingRiskType,
      creditTheftRiskType,
      spamRobocallsRiskType,
      homeTitleTheftRiskType,
    ].every(item => item === RiskTypes.low);

    const dataTradingRiskType = isEveryCategoryLowRisk ? RiskTypes.low : RiskTypes.high;

    const socialProfilesData: TSocialProfiles = {
      facebook: [...parsedData.socialFacebook],
      google: [...parsedData.socialGoogle],
      youtube: [...parsedData.socialYoutube],
      instagram: [...parsedData.socialInstagram],
      yahoo: [...parsedData.socialYahoo],
      skype: [...parsedData.socialSkype],
      reddit: [...parsedData.socialReddit],
      stackoverflow: [...parsedData.socialStackoverflow],
      pinterest: [...parsedData.socialPinterest],
    };

    const generalInfoData: Array<{value: string; hidden?: boolean}> = [];

    parsedData.names.length && generalInfoData.push(parsedData.names[0]);
    parsedData.gender.length && generalInfoData.push(parsedData.gender[0]);
    parsedData.language.length && generalInfoData.push(parsedData.language[0]);

    const newRiskCategories: TRiskCategoriesState = {
      identityTheft: {
        riskType: identityTheftRiskType,
        person: {
          images: parsedData.images,
          name: parsedData.names.length ? parsedData.names[0].value : '',
          gender: parsedData.gender.length ? parsedData.gender[0].value : '',
          yearsOld: parsedData.yearsOld.length ? parsedData.yearsOld[0] : '',
          language: parsedData.language,
          riskBadge: 'Publicy Available',
        },
        items: {
          ids: {
            name: 'IDs',
            riskBadge: 'Exposed',
            subItems: {
              idsSsn: {
                name: 'SSN',
                data: parsedData.idsSsn,
              },
              idsDriversLicence: {
                name: 'Driver’s license',
                data: parsedData.idsDriversLicence,
              },
              idsPassport: {
                name: 'Passport',
                data: parsedData.idsPassport,
              },
              idsTaxId: {
                name: 'Tax ID',
                data: parsedData.idsTaxId,
              },
            },
          },
          phones: {
            name: 'Phones',
            riskBadge: 'Exposed',
            data: parsedData.phones,
          },
          car: {
            name: 'Car',
            riskBadge: 'Publicy Available',
            data: parsedData.car,
          },
          education: {
            name: 'Education',
            riskBadge: 'Publicy Available',
            data: parsedData.education,
          },
          careerDetails: {
            name: 'Career details',
            riskBadge: 'Publicy Available',
            data: parsedData.careerDetails,
          },
          closeConnections: {
            name: 'Close connections',
            riskBadge: 'Publicy Available',
            data: parsedData.closeConnections,
          },
          socialProfiles: {
            name: 'Social profiles',
            riskBadge: 'Publicy Available',
            socialProfilesData,
          },
        },
      },
      armaniDataTrading: {
        riskType: identityTheftRiskType,
        items: {
          generalInfo: {
            name: 'General Info',
            riskBadge: 'Exposed',
            data: generalInfoData,
          },
          ids: {
            name: 'IDs',
            riskBadge: 'Exposed',
            subItems: {
              idsSsn: {
                name: 'SSN',
                data: parsedData.idsSsn,
              },
              idsDriversLicence: {
                name: 'Driver’s license',
                data: parsedData.idsDriversLicence,
              },
              idsPassport: {
                name: 'Passport',
                data: parsedData.idsPassport,
              },
              idsTaxId: {
                name: 'Tax ID',
                data: parsedData.idsTaxId,
              },
            },
          },
          phones: {
            name: 'Phones',
            riskBadge: 'Exposed',
            data: parsedData.phones,
          },
          socialProfiles: {
            name: 'Social profiles',
            riskBadge: 'Publicy Available',
            socialProfilesData,
          },
        },
      },
      accountHijacking: {
        riskType: accountHijackingRiskType,
        items: {
          accounts: {
            name: 'Accounts',
            riskBadge: 'Exposed',
            data: parsedData.accounts,
          },
          logins: {
            name: 'Logins',
            riskBadge: 'Exposed',
            data: parsedData.logins,
          },
          passwords: {
            name: 'Passwords',
            riskBadge: 'Exposed',
            data: parsedData.passwords,
          },
          securityQuestions: {
            name: 'Security questions',
            data: parsedData.securityQuestions,
            riskBadge: 'Exposed',
          },
        },
      },
      homeTitleTheft: {
        riskType: homeTitleTheftRiskType,
        items: {
          locations: {
            name: 'Locations',
            riskBadge: 'Exposed',
            data: parsedData.locations,
          },
          ipAddresses: {
            name: 'IP addresses',
            riskBadge: 'Exposed',
            data: parsedData.ipAddresses,
          },
        },
      },
      creditTheft: {
        riskType: creditTheftRiskType,
        items: {
          creditCard: {
            name: 'Credit card',
            riskBadge: 'Exposed',
            subItems: {
              creditCardNumberFull: {
                name: 'Credit card number',
                data: [...parsedData.creditCardNumberFull, ...parsedData.creditCardNumberLastFour],
              },
              creditCardCode: {
                name: 'Credit card code',
                data: parsedData.creditCardCode,
              },
              creditCardExpiration: {
                name: 'Expiration date',
                data: parsedData.creditCardExpiration,
              },
            },
          },
          mortgageDetails: {
            name: 'Mortgage details',
            riskBadge: 'Exposed',
            subItems: {
              mortgageDetailsAmount: {
                name: 'Amount',
                data: parsedData.mortgageDetailsAmount,
              },
              mortgageDetailsLenderName: {
                name: 'Lender name',
                data: parsedData.mortgageDetailsLenderName,
              },
              mortgageDetailsLoanType: {
                name: 'Loan type',
                data: parsedData.mortgageDetailsLoanType,
              },
            },
          },
          bankDetails: {
            name: 'Bank details',
            subItems: {
              bankDetailsAccountNumber: {
                name: 'Account number',
                data: parsedData.bankDetailsAccountNumber,
              },
            },
            riskBadge: 'Exposed',
          },
          creditRating: {
            name: 'Credit rating',
            riskBadge: 'Exposed',
            data: parsedData.creditRating,
          },
        },
      },
      spamRobocalls: {
        riskType: spamRobocallsRiskType,
        items: {
          phones: {
            name: 'Phones',
            riskBadge: 'Exposed',
            data: parsedData.phones,
          },
          logins: {
            name: 'Logins',
            riskBadge: 'Exposed',
            data: parsedData.logins,
          },
          socialProfiles: {
            name: 'Social profiles',
            riskBadge: 'Publicy Available',
            socialProfilesData,
          },
        },
      },
      dataTrading: {
        riskType: dataTradingRiskType,
        items: {
          spokeo: {
            name: 'spokeo',
            data: getDataTradingData(parsedData) || [{value: action.payload.email}],
            riskBadge: dataTradingRiskType === RiskTypes.high ? 'High Risk Of Trade' : 'Low Risk Of Trade',
          },
          peoplefinders: {
            name: 'peoplefinders',
            data: getDataTradingData(parsedData) || [{value: action.payload.email}],
            riskBadge: dataTradingRiskType === RiskTypes.high ? 'High Risk Of Trade' : 'Low Risk Of Trade',
          },
          peekyou: {
            name: 'peekyou',
            data: getDataTradingData(parsedData) || [{value: action.payload.email}],
            riskBadge: dataTradingRiskType === RiskTypes.high ? 'High Risk Of Trade' : 'Low Risk Of Trade',
          },
        },
      },
    };

    const availableCategoryTypes: Array<TRiskCategoryType> = yield select(selectAvailableCategoryTypes);

    const riskMap = {low: 0, moderate: 0, high: 0};
    availableCategoryTypes.forEach(item => {
      if (newRiskCategories[item].riskType === RiskTypes.low) {
        riskMap['low']++;
      }
      if (newRiskCategories[item].riskType === RiskTypes.moderate) {
        riskMap['moderate']++;
      }
      if (newRiskCategories[item].riskType === RiskTypes.high) {
        riskMap['high']++;
      }
    });

    let globalRiskType = RiskTypes.unknown;
    if (riskMap.high) {
      globalRiskType = RiskTypes.high;
    } else if (riskMap.moderate) {
      globalRiskType = RiskTypes.moderate;
    } else {
      globalRiskType = RiskTypes.low;
    }

    sendPageEventGTM('/scan-completed', 'scan-completed');

    sendAnalytics({
      eventName: 'user_status_defined',
      payload: {
        global: globalRiskType,
        identityTheft: identityTheftRiskType,
        accountHijacking: accountHijackingRiskType,
        creditTheft: creditTheftRiskType,
        spamRobocalls: spamRobocallsRiskType,
        homeTitleTheft: homeTitleTheftRiskType,
        dataTrading: dataTradingRiskType,
      },
    });

    yield put(setRiskCategories(newRiskCategories));
    yield put(setPageData({isLoading: false, isDataFetched: true, exposedItemsCount, globalRiskType}));
  } catch (e) {
    console.error(e);
    yield put(setPageData({isLoading: false, isDataFetched: false, isShowErrorPopup: true, email: undefined}));
    sendAnalytics({
      eventName: 'scan_was_failed',
      payload: {error_message: JSON.stringify(e)},
    });
  }
}

export function* pageWatcher() {
  yield takeEvery(GET_RISK_CATEGORIES, getRiskCategoriesSaga);
}
