import React from 'react';
import {CategoryContainer} from '@js/landing/components/category/category.container';
import {CategoriesSectionProps} from './categories-section.types';
import './_categories-section.scss';
import {BreachIsThreatContainer} from '../breach-is-threat/breach-is-threat.container';
import {RiskTypes} from '@js/landing/redux/constants';

export const CategoriesSectionComponent: React.FC<CategoriesSectionProps> = ({
  availableCategories,
  isDataFetched,
  isLoading,
  globalRiskType,
  email,
}) => {
  const riskCategories = availableCategories.map((categoryName, index, arr) => {
    return (
      <CategoryContainer
        categoryName={categoryName}
        key={categoryName}
        partNumber={index + 1}
        partsCount={arr.length}
      />
    );
  });

  let bottomBlock = null;

  if (!isLoading && isDataFetched && globalRiskType == RiskTypes.low) {
    bottomBlock = (
      <div className="categories-section__bottom-block">
        <BreachIsThreatContainer />
      </div>
    );
  }

  const title =
    !isLoading && isDataFetched && FL_LANDING_PRODUCT === 'acer'
      ? `Exposed Data Report for “${email}”`
      : 'Exposed Data Risk Report';

  return (
    <section className="categories-section">
      <div className="categories-section__inner">
        <div className="categories-section__header">
          <h2 className="categories-section__title">{title}</h2>
          <p className="categories-section__description">
            {FL_LANDING_PRODUCT === 'acer'
              ? `With over 100 billion private data records leaked or hacked from all over the web, the amount of identity-related fraud is growing steadily. Your custom report includes where your personal data may be compromised.`
              : `With over 100 billion private data records leaked or hacked from all over the web the amount of Identity-related fraud is growing steadily.`}
          </p>
        </div>
        <div className="categories-section__content">
          {riskCategories}
          {bottomBlock}
        </div>
      </div>
    </section>
  );
};
