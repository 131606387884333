import React from 'react';
import {TooltipProps} from './tooltip.types';
import './_tooltip.scss';

export const TooltipComponent: React.FC<TooltipProps> = props => {
  const classList = ['tooltip'];
  const wrapperClassList = ['tooltip-wrapper'];
  if (props.modifier) {
    classList.push(`tooltip--${props.modifier}`);
  }
  if (props.placement) {
    classList.push(`tooltip--${props.placement}`);
  } else {
    classList.push('tooltip--top');
  }
  if (props.display === 'inline-block') {
    wrapperClassList.push('tooltip-wrapper--ib');
  }

  return (
    <div className={wrapperClassList.join(' ')}>
      <div className={classList.join(' ')}>{props.text}</div>
      {props.children}
    </div>
  );
};
