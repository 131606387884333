import React, {FC} from 'react';
import IconFigLeafCurrentColor from '@common/images/icon-fl-logo-current-color.svg';

import './_fl-gradient-logo.scss';

export const FigleafGradientLogoComponent: FC = () => {
  return (
    <div className="fl-gradient-logo">
      <IconFigLeafCurrentColor />
    </div>
  );
};
