import React, {FormEvent, memo, useState} from 'react';
import './_header.scss';
import {HeaderProps} from './header.types';
import IconFigLeafAuraCurrentColor from '@common/images/icon-fl-aura-logo-current-color.svg';
import IconLowRisk from '@common/images/icon-low-risk.svg';
import IconUnknownRisk from '@common/images/icon-question-mark.svg';
import IconExclamationCurrentColor from '@common/images/icon-exclamation-current-color.svg';
import RiskScaleHigh from './images/risk-scale-high.png';
import RiskScaleHigh2x from './images/risk-scale-high2x.png';
import RiskScaleMedium from './images/risk-scale-medium.png';
import RiskScaleMedium2x from './images/risk-scale-medium2x.png';
import RiskScaleLow from './images/risk-scale-low.png';
import RiskScaleLow2x from './images/risk-scale-low2x.png';
import RiskScaleUnknown from './images/risk-scale-unknown.png';
import RiskScaleUnknown2x from './images/risk-scale-unknown2x.png';
import {ButtonComponent} from '../../../pure-components/button/button.component';
import {TransparentInputComponent} from '../../../pure-components/transparent-input/transparent-input.component';
import {WhiteInputComponent} from '@js/pure-components/white-input/white-input.component';
import {Spinner} from '@js/pure-components/spinner/spinner.component';
import {RiskTypes} from '@js/landing/redux/constants';
import {emailRegex} from '@js/utils/helpers';
import {categoriesConfig} from '../category/config';

export const Header = memo<HeaderProps>(
  ({
    isLoading,
    isDataFetched,
    availableCategoryTypes,
    riskCategories,
    email,
    getRiskCategories,
    exposedItemsCount,
    globalRiskType,
  }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [isInputValueValid, setIsInputValueValid] = useState(true);

    const handleInputChange = (value: string) => {
      if (!isInputValueValid) {
        setIsInputValueValid(emailRegex.test(value));
      }
      setInputValue(value);
    };

    const handleSubmitEmail = (event: FormEvent, location: 'top' | 'bottom') => {
      event.preventDefault();
      if (!emailRegex.test(inputValue)) {
        setIsInputValueValid(false);
      } else {
        setIsInputValueValid(true);
        getRiskCategories({email: inputValue, source: location === 'top' ? 'headerTopCta' : 'headerBottomCta'});
        setInputValue('');
      }
    };

    let riskScaleImage = null;
    let riskScaleText = '';
    let wrapperClassName = 'header--unknown';
    switch (globalRiskType) {
      case RiskTypes.high: {
        riskScaleImage = (
          <img
            src={RiskScaleHigh}
            alt="high risk"
            srcSet={`${RiskScaleHigh} 1x, ${RiskScaleHigh2x} 2x`}
            className="header__rank-image"
          />
        );
        riskScaleText = 'High';
        wrapperClassName = 'header--high';
        break;
      }
      case RiskTypes.moderate: {
        riskScaleImage = (
          <img
            src={RiskScaleMedium}
            alt="moderate risk"
            srcSet={`${RiskScaleMedium} 1x, ${RiskScaleMedium2x} 2x`}
            className="header__rank-image"
          />
        );
        riskScaleText = 'Moderate';
        wrapperClassName = 'header--moderate';
        break;
      }
      case RiskTypes.low: {
        riskScaleImage = (
          <img
            src={RiskScaleLow}
            alt="low risk"
            srcSet={`${RiskScaleLow} 1x, ${RiskScaleLow2x} 2x`}
            className="header__rank-image"
          />
        );
        riskScaleText = 'Low';
        wrapperClassName = 'header--low';
        break;
      }
      default: {
        riskScaleImage = (
          <img
            src={RiskScaleUnknown}
            alt="unknown risk"
            srcSet={`${RiskScaleUnknown} 1x, ${RiskScaleUnknown2x} 2x`}
            className="header__rank-image"
          />
        );
        riskScaleText = 'Unknown';
        wrapperClassName = 'header--unknown';
      }
    }

    let riskDescription = '';
    let titleText = '';

    if (!isDataFetched && !isLoading) {
      riskDescription =
        FL_LANDING_PRODUCT === 'acer'
          ? 'Your privacy is important to Acer. At no cost to Acer users, Acer provides a privacy checkup, powered by FigLeaf. Enter your email address to get your exposed data risk report, across these categories:'
          : 'Scan your email to see if your private data was compromised.\nWe check your private data abuse risks across these categories:';

      titleText =
        FL_LANDING_PRODUCT === 'acer'
          ? 'Who Has Your Private Information?'
          : `Your Personal Data Exposure is ${riskScaleText}`;
    } else if (isLoading) {
      riskDescription = `We are searching for private data points associated with ${email}.\nHere are major data abuse associated risks that we are checking for:`;
      titleText = 'Scanning...';
    } else {
      riskDescription = `We found <strong>${
        exposedItemsCount || 0
      } exposed private data points</strong> associated with ${email}.\nHere are major data abuse associated risks that we checked:`;
      titleText = `Your Personal Data Exposure is ${riskScaleText}`;
    }

    let ctaComponent = null;

    if (!isLoading && !isDataFetched) {
      ctaComponent = (
        <div className="header__cta-wrapper">
          <form
            onSubmit={(event: FormEvent) => {
              handleSubmitEmail(event, 'top');
            }}
            className="header__cta"
          >
            <WhiteInputComponent
              value={inputValue}
              onChange={setInputValue}
              placeholder="Your email address..."
              isValid={isInputValueValid}
              errorMessage="Please, enter a valid email address"
            />
            <ButtonComponent type="submit" text={'Free Privacy Checkup'} mode="green" />
          </form>
          <span className="header__cta-description">We 100% guarantee no spam in your inbox and no data selling</span>
        </div>
      );
    }

    const amountOfExposedCategories = availableCategoryTypes.filter(
      category => riskCategories[category].riskType !== RiskTypes.low,
    ).length;

    return (
      <div className={`header ${wrapperClassName}`}>
        <div className="header__content">
          <div className="header__top">
            <IconFigLeafAuraCurrentColor className="header__logo" />
            {isDataFetched && globalRiskType === RiskTypes.low && (
              <form
                onSubmit={(event: FormEvent) => {
                  handleSubmitEmail(event, 'bottom');
                }}
                className="header__cta header__cta--top"
              >
                <TransparentInputComponent
                  type="email"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Try another email..."
                  isValid={isInputValueValid}
                  errorMessage="Please, enter a valid email address"
                />
                <ButtonComponent type="submit" text="Check" mode="light-green" />
              </form>
            )}
          </div>
          <div className="header__bottom">
            <div className="header__details">
              <div className="header__title">{titleText}</div>
              <div className="header__subtitle" dangerouslySetInnerHTML={{__html: riskDescription}} />

              <div className="header__badges">
                {availableCategoryTypes.map((item, index) => {
                  let iconComponent = null;
                  if (isLoading) {
                    iconComponent = <Spinner sizeModifier="bg" />;
                  } else if (riskCategories[item].riskType === RiskTypes.low) {
                    iconComponent = <IconLowRisk />;
                  } else if (riskCategories[item].riskType === RiskTypes.moderate) {
                    iconComponent = <IconExclamationCurrentColor className="header__badge--moderate" />;
                  } else if (riskCategories[item].riskType === RiskTypes.high) {
                    iconComponent = <IconExclamationCurrentColor className="header__badge--high" />;
                  } else {
                    iconComponent = <IconUnknownRisk />;
                  }

                  return (
                    <a className="header__badge" href={`#${item}`} key={index}>
                      <span className="header__badge-text">
                        <div className="header__badge-icon">{iconComponent}</div>
                        {categoriesConfig[item].title}
                      </span>
                    </a>
                  );
                })}
              </div>
              {ctaComponent}
            </div>
            <div className="header__rank">
              {riskScaleImage}
              <span className="header__rank-title">Data Exposure</span>
              <span className="header__rank-state">{riskScaleText}</span>
              <span className="header__rank-description">
                {isDataFetched
                  ? `${amountOfExposedCategories} out of ${availableCategoryTypes.length} risk categories`
                  : 'Check your email to start'}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

Header.displayName = 'Header';
