import {connect} from 'react-redux';
import {AppState} from '@js/landing/redux/rootReducer';
import {CategoriesSectionComponent} from './categories-section.component';
import {
  selectAvailableCategoryTypes,
  selectEmail,
  selectGlobalRiskType,
  selectIsDataFetched,
  selectIsLoading,
} from '@js/landing/redux/selectors';

const mapStateToProps = (state: AppState) => ({
  availableCategories: selectAvailableCategoryTypes(state),
  isDataFetched: selectIsDataFetched(state),
  isLoading: selectIsLoading(state),
  globalRiskType: selectGlobalRiskType(state),
  email: selectEmail(state) || '',
});

const mapDispatchToProps = {};

export const CategoriesSectionContainer = connect(mapStateToProps, mapDispatchToProps)(CategoriesSectionComponent);
