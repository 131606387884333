import {PlanTitle} from '@js/buynow/redux/types';
import {plansConfig} from './constants';

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const getPlanData = (selectedPlan: PlanTitle) => {
  const selectedPlanConfig = plansConfig.find(plan => plan.title === selectedPlan) || plansConfig[0];

  const totalPrice = selectedPlanConfig.pricePerMonth * selectedPlanConfig.amountOfMonths;
  const priceWithoutDiscount = totalPrice + selectedPlanConfig.save;

  const description =
    selectedPlanConfig.amountOfPeople > 1 ? `${selectedPlanConfig.amountOfPeople} people` : 'Single use';

  return {
    ...selectedPlanConfig,
    priceWithoutDiscount: priceWithoutDiscount.toFixed(2),
    totalPrice: totalPrice.toFixed(2),
    save: selectedPlanConfig.save,
    description,
  };
};
