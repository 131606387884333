import {RiskTypes, SET_PAGE_DATA, SET_PRESCAN_KEY} from '../constants';
import {PageData, TSetPageDataAction, TSetPrescanKeyAction} from '../types';

const initialState: PageData = {
  isLoading: false,
  isDataFetched: false,
  availableCategoryTypes: [],
  isShowErrorPopup: false,
  isShowPaywallPopup: false,
  globalRiskType: RiskTypes.unknown,
  buyNowUrl: '/buynow',
  prescanKey: 'see',
};

type TActionsType = TSetPageDataAction | TSetPrescanKeyAction;

export const pageDataReducer = (state = initialState, action: TActionsType): PageData => {
  switch (action.type) {
    case SET_PAGE_DATA:
    case SET_PRESCAN_KEY:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
